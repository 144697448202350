export function usePopup() {
    const popup = useState('popup', () => shallowRef(null));
    const props = useState('props', () => shallowRef(null));
    
    function setPopup(content, contentProps) {
        popup.value = content;
        props.value = contentProps;
    }

    return {
        popup,
        props,
        setPopup,
    };
}
