import { getUserData } from "~/composables/useUser.js";

export default defineNuxtPlugin(async (nuxtApp) => {
	const session = useCookie("session");
	const user = useUser();

	setTimeout(async () => {
		if (session.value) {
			const userData = await getUserData(session.value);
			if (userData) {
				user.value = userData;
				setUser(userData);
			}
		}
	}, 500);
});
