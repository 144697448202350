<template>
	<div class="mt-20 flex flex-col mb-10 justify-center w-1/2 m-auto m-lg:w-11/12">
		<h5 class="text-center">Minecraft IP List</h5>
		<div class="grid grid-cols-3 gap-6 mt-4 text-center m-lg:flex m-lg:flex-col">
			<div class="text-right flex flex-col m-lg:text-center">
				<p>MCIP Links</p>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" to="/">Minecraft Servers</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" to="/server/add">Add your server</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" to="/contact">Contact us</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" to="/terms-and-conditions">Terms</NuxtLink>
			</div>
			<div class="text-center flex flex-col m-lg:text-center">
				<p>Community</p>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://discord.gg/npsBSDs2hX">MCIP Discord
				</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" to="/blog/" :external="true">MCIP Blog</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://x.com/minecraftiplist">MCIP Twitter</NuxtLink>
			</div>
			<div class="text-left flex flex-col w-fit m-lg:text-center m-lg:w-full">
				<p>External Links</p>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://discadia.com/">Discord Servers</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://skinsmc.org/">Minecraft Skins</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://www.minecraft.net/en-us/download/server">Minecraft Server Software</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://minecraft.net/en-us/store/minecraft-deluxe-collection-pc/">Buy Minecraft</NuxtLink>
				<NuxtLink class="text-gray-900 hover:text-gray-700 whitespace-nowrap" target="_blank" to="https://en.wikipedia.org/wiki/Minecraft">Minecraft Wikipedia</NuxtLink>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
</script>