<template>
    <div>
        <h4 class="font-bold mb-3 mt-3">
	        What are Minecraft Servers?
        </h4>
        <p class="font-normal text-sm">
            A Minecraft server is a place where people can play Minecraft online
            together. These servers can be hosted by the players themselves or
            provided by third parties. Once you are connected to a Minecraft server
            you can play with a bunch of other players on the same world.
            <br>
            <br>
            Minecraft servers are fun and are one of the best ways to enjoy Minecraft.
            You can build your own creations, play minigames or become part of a
            community. Our Minecraft Server List website is dedicated to helping you
            find the best Minecraft server for you and your friends.
        </p>
	    <h4 class="font-bold mb-3 mt-3">
	        How do I find a Minecraft server to play?
        </h4>
	    <p class="font-normal text-sm">
            Finding a great Minecraft server to play is as easy as searching through a
            Minecraft server list such as this one. If you’re looking for a specific
            type of server, you can use tags to narrow down your search.
            <br>
            <br>
            For example, if you’re looking for a server with a PVP arena, you can
            search through the list until you find one that fits your needs. In
            addition to finding servers, our Minecraft server list will show you
            helpful information about each server such as the amount of players
            online, server uptime, server gamemodes, and more.
        </p>
	    <h4 class="font-bold mb-3 mt-3">
	        How do I join a Minecraft server?
        </h4>
	    <p class="font-normal text-sm">
            To connect to a Minecraft server, you should first find a Minecraft server
            IP address from our list that you want to join. After choosing a server
            you like, enter the IP address in the “server address” box in your
            Minecraft client and connect to it.
            <br>
            <br>
            To find this "server address" box first click “Multiplayer” in the
            Minecraft game menu and click "Add server". Now you can type the server IP
            address in the "server address" box. Click “Done” and then click “Join
            Server”. If you did everything correctly, you should be connected to the
            server and able to play with others.
        </p>
    </div>
</template>