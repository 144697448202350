export default function useUser() {
	return useState("user", () => null);
}

export function setUser(userData) {
	if (userData) {
		// Ensure these are called within the correct Nuxt context
		const loggedInCookie = useCookie("loggedIn");
		const usernameCookie = useCookie("username");
		loggedInCookie.value = 'true';
		usernameCookie.value = userData.username;
	} else {
		const loggedInCookie = useCookie("loggedIn");
		const usernameCookie = useCookie("username");
		const sessionCookie = useCookie("session");
		loggedInCookie.value = 'false';
		usernameCookie.value = null;
		sessionCookie.value = null;
	}
}

export async function logout() {
	try {
		await $fetch("/api/user/logout", {
			method: "POST",
			credentials: "include",
		});
	} catch (e) {
		console.error(e);
	}
}

export async function getUserData(session) {
	try {
		const headers = session ? { "Cookie": "session=" + session } : {};
		const data = await $fetch("/api/user", { headers });
		return data;
	} catch (e) {
		console.error(e);
	}
};