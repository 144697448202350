<template>
	<div class="mt-24">
		<p class="text-center text-lg mb-4">
			Minecraft IP List
		</p>
		<div class="flex gap-5 justify-center flex-wrap">
			<div class="flex bg-gray-700 text-white-0 gap-3 px-3 py-1.5 rounded-xl">
				<div class="self-center">
					<img src="~/assets/img/logo.svg" alt="Minecraft IP List logo"
					     class="w-10 rounded-1/2" width="28" height="28">
				</div>
				<div class="flex-1 min-w-48">
					<div>
						MinecraftIPList Discord
					</div>
					<div class="text-gray-500">
						+ Support
					</div>
					<div class="text-green-100">
						+ Announcements
					</div>
				</div>
				<div class="flex items-center">
					<NuxtLink to="https://discadia.com/server/mcip-list-minecraftiplist-co/" class="bg-green-200 flex gap-1 items-center px-2.5 py-1 rounded-lg hover:bg-green-250">
						<span>Join</span>
						<img src="~/assets/icons/link.svg" alt="Link icon" width="15" height="15">
					</NuxtLink>
				</div>
			</div>
			<div class="flex bg-gray-100 border border-gray-300 text-black gap-3 px-3 py-1.5 rounded-xl">
				<div class="self-center">
					<img src="~/assets/img/logo.svg" alt="Minecraft IP List logo"
					     class="w-10 rounded-1/2" width="28" height="28">
				</div>
				<div class="flex-1 min-w-48">
					<div>
						MinecraftIPList Blog
					</div>
					<div class="text-gray-900">
						+ Guides
					</div>
					<div class="text-gray-900">
						+ Insights
					</div>
				</div>
				<div class="flex items-center">
					<NuxtLink to="/blog/" :external="true" class="bg-gray-800 flex gap-1 items-center px-2.5 py-1 rounded-lg text-white-0 hover:bg-gray-900">
						<span>Read</span>
						<img src="~/assets/icons/link.svg" alt="Link icon" width="15" height="15">
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
</script>